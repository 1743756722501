import {
  SampleCollectionFailure,
  SampleDropOffFailure,
  SampleStatus,
  SetProcedureStatusesRequest,
  SupplyItemWithSupplyID,
} from '@caresend/types';
import { ProceduresState } from '@caresend/ui-components';

import type { CustomActionContext } from '@/store/model';
import { SetOrUnset } from '@/store/modules/supplies/model';

export interface ProcedureDateTimeInfo {
  creation?: string;
  requested: string[] | undefined;
}

export interface SetSampleStatus {
  procedureID: string;
  sampleID: string;
  status: SampleStatus;
  collectionFailure?: SampleCollectionFailure;
  dropOffFailure?: SampleDropOffFailure;
}

export interface SetSampleSupplyItem {
  procedureID: string;
  sampleID: string;
  supplyItemID?: string;
}

export interface SetSampleAmberBagInStore {
  amberBag: SupplyItemWithSupplyID | null;
  procedureID: string;
  sampleID: string;
}

export interface SetProcedureInsulatedBag {
  insulatedBagSupplyItemID: string | null;
  insulatedBagSupplyID: string;
  procedureID: string;
  sampleID: string;
}

export interface SetOrUnsetProcedureSpecimenBag {
  instruction: SetOrUnset;
  procedureID: string;
  specimenBagSupplyItemID: string;
  specimenBagSupplyID: string;
}

export interface SetOrUnsetProcedureKit {
  instruction: SetOrUnset;
  procedureID: string;
  procedureKitSupplyItemID: string;
  procedureKitSupplyID: string;
}

export interface SetProcedureSpecimenBagSupplyItem {
  procedureID: string;
  specimenBagSupplyItem?: SupplyItemWithSupplyID;
}

export interface SetProcedureInsulatedBagInStore {
  insulatedBagSupplyItemID: string | null;
  insulatedBagSupplyID: string;
  procedureID: string;
  sampleID: string;
  previousSupplyItem: GetPreviousSupplyItem;
}

/**
 * Must match supply names in `variables/supplies`
 *
 * TODO: Should this be moved to types?
 */
export enum SupplyItemType {
  AMBER_BAG = 'Amber Bag',
  CARESEND_BAG = 'CareSend Bag',
  CONTAINER = 'container',
  INSULATED_BAG = 'Insulated Bag (Including ice pack)',
  MAIL_IN_BOX = 'Mail-in box',
  PROCEDURE_BAG = 'Procedure Bag',
  SPECIMEN_BAG = 'Specimen Bag',
  TUBE = 'tube',
}

export interface GetPreviousSupplyItem {
  supplyItem: SupplyItemWithSupplyID;
  type: SupplyItemType;
}

export interface UnsetSampleAmberBagInStore{
  amberBagID: string;
  procedureID: string;
  sampleID: string;
}

export interface SetSampleAmberBag {
  procedureID: string;
  sampleID: string;
  amberBagSupplyID?: string;
  amberBagSupplyItemID: string | null;
  tubeSupplyItemID?: string;
  currentSampleAmberBag?: SupplyItemWithSupplyID;
}

export type ExtraProceduresActionContext = CustomActionContext<'procedures', ProceduresState>

export type ExtraProceduresActions = {
  'procedures/setProcedureStatuses': (
    context: ExtraProceduresActionContext,
    params: SetProcedureStatusesRequest,
  ) => void;

  'procedures/fetchProcedureData': (
    context: ExtraProceduresActionContext,
    id: string,
  ) => void;
}
