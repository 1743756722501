import { getUserHmacKeyRequest } from '@/database/firebase/API';

export const getUserHmacKey = async (userID: string) => {
  let key = localStorage.getItem('cachedHmacKey');
  const timestamp = localStorage.getItem('cachedHmacKeyTimestamp');
  const isTimestampOutdated = !timestamp || new Date(timestamp).getTime() < (Date.now() - 1000 * 60 * 60 * 24);

  if (!key || isTimestampOutdated) {
    const hmacKeyRequest = await getUserHmacKeyRequest({ userID });
    await localStorage.setItem('cachedHmacKey', hmacKeyRequest.key);
    await localStorage.setItem('cachedHmacKeyTimestamp', new Date().toISOString());
    key = hmacKeyRequest.key;
  }

  return key;
};

export const setIntercomUserInfo = async ({ firstName, lastName, email, userID }: {
  firstName: string;
  lastName: string;
  email: string;
  userID: string;
}) => {
  const name = `${firstName} ${lastName}`;
  const userHmacKey = await getUserHmacKey(userID);
  window.Intercom('update', { name, user_id: userID, email, user_hash: userHmacKey });
};

export const showIntercomButton = () => {
  window.Intercom('update', {
    hide_default_launcher: false,
  });
};

export const hideIntercomButton = () => {
  window.Intercom('update', {
    hide_default_launcher: true,
  });
};

export const showIntercomMessenger = () => {
  showIntercomButton();
  window.Intercom('show');
  window.Intercom('onHide', () => {
    hideIntercomButton();
  });
};
