import {
  ApproveUserRequest,
  AssignNurseToItineraryRequest,
  AssignNurseToShiftRequest,
  AssignNurseToShiftResponse,
  CreateUserRequest,
  CreateUserResponse,
  CreateUserWithEmailLinkRequest,
  CreateUserWithEmailLinkResponse,
  DeleteUserRequest,
  DeleteUserResponse,
  GenerateAuthTokenRequest,
  GenerateAuthTokenResponse,
  GenerateProcedureInstructionsRequest,
  GenerateProcedureInstructionsResponse,
  GetAlgoliaAPIKeyForNurseItinerariesRequest,
  GetAlgoliaAPIKeyResponse,
  GetAlgoliaSuppliesAPIKeyRequest,
  GetAlgoliaSuppliesAPIKeyResponse,
  GetCourseUrlRequest,
  GetCourseUrlResponse,
  GetItineraryDataRequest,
  GetItineraryDataResponse,
  GetItineraryDrivingEstimateRequest,
  GetItineraryDrivingEstimateResponse,
  GetShiftsOfferedResponse,
  GetSupplyItemsDataRequest,
  GetSupplyItemsDataResponse,
  GetTransactionsRequest,
  GetTransactionsResponse,
  GetUserCourseAuthTokenRequest,
  GetUserCourseAuthTokenResponse,
  GetUserHmacKeyRequest,
  GetUserHmacKeyResponse,
  GetUserItinerariesResponse,
  GetUserOrdersRequest,
  GetUserPasswordCreatedRequest,
  GetUserPasswordCreatedResponse,
  RefreshTokenOnFailureRequest,
  RefreshTokenOnFailureResponse,
  ResetUserPasswordRequest,
  ResetUserPasswordResponse,
  SendInvitationsToOfficeRequest,
  SendInvitationsToOfficeResponse,
  SetProcedureStatusesRequest,
  SetProcedureStatusesResponse,
  SetWaypointActionStatusRequest,
  SetWaypointActionStatusResponse,
  WithdrawUserAvailableBalanceRequest,
  WithdrawUserAvailableBalanceResponse,
} from '@caresend/types';
import { firebaseCallable } from '@caresend/ui-components';

import { batchedCallable } from '@/database/firebase/API/batching';

export const getSupplyItemsDataRequest = batchedCallable<
 GetSupplyItemsDataRequest,
 GetSupplyItemsDataResponse
>('getSupplyItemsData');

/**
 * Single
 */

export const approveUserRequest = firebaseCallable<
  ApproveUserRequest,
  void
>('approveUser');

export const assignNurseToItineraryRequest = firebaseCallable<
  AssignNurseToItineraryRequest,
  void
>('assignNurseToItinerary');

export const assignNurseToShiftRequest = firebaseCallable<
  AssignNurseToShiftRequest,
  AssignNurseToShiftResponse
>('assignNurseToShift');

export const checkEmailAlreadyInUseRequest = firebaseCallable<
  { email: string },
  { isInUse: boolean; userID?: string }
>('checkEmailAlreadyInUse');

export const createUserWithEmailLinkRequest = firebaseCallable<
  CreateUserWithEmailLinkRequest,
  CreateUserWithEmailLinkResponse
>('createUserWithEmailLink');

export const createUserRequest = firebaseCallable<
  CreateUserRequest,
  CreateUserResponse
>('createUser');

export const deleteUserRequest = firebaseCallable<
  DeleteUserRequest,
  DeleteUserResponse
>('deleteUser');

export const getAlgoliaAPIKeyForNurseItinerariesRequest = firebaseCallable<
  GetAlgoliaAPIKeyForNurseItinerariesRequest,
  GetAlgoliaAPIKeyResponse
>('getAlgoliaAPIKeyForNurseItineraries');

export const getAlgoliaAPIKeyForSearchPatientsRequest = firebaseCallable<
  void,
  GetAlgoliaAPIKeyResponse
>('getAlgoliaAPIKeyForSearchPatients');

export const getAlgoliaAPIKeyForSearchNameOnOfficesRequest = firebaseCallable<
  void,
  GetAlgoliaAPIKeyResponse
>('getAlgoliaAPIKeyForSearchNameOnOffices');

export const getAlgoliaSuppliesAPIKeyRequest = firebaseCallable<
  GetAlgoliaSuppliesAPIKeyRequest,
  GetAlgoliaSuppliesAPIKeyResponse
>('getAlgoliaSuppliesAPIKey');

export const getCourseUrlRequest = firebaseCallable<
  GetCourseUrlRequest,
  GetCourseUrlResponse
>('getCourseUrl');

export const getItineraryDataRequest = batchedCallable<
 GetItineraryDataRequest,
 GetItineraryDataResponse
>('getItineraryData');

export const getTransactionsRequest = firebaseCallable<
GetTransactionsRequest,
GetTransactionsResponse
>('getTransactions');

export const generateAuthTokenRequest = firebaseCallable<
  GenerateAuthTokenRequest,
  GenerateAuthTokenResponse
>('generateAuthToken');

export const generateProcedureInstructionsRequest = firebaseCallable<
  GenerateProcedureInstructionsRequest,
  GenerateProcedureInstructionsResponse
>('generateProcedureInstructions');

export const getShiftsOfferedRequest = firebaseCallable<
  void,
  GetShiftsOfferedResponse
>('getShiftsOffered');

export const getUserCourseAuthTokenRequest = firebaseCallable<
  GetUserCourseAuthTokenRequest,
  GetUserCourseAuthTokenResponse
>('getUserCourseAuthToken');

export const getUserHmacKeyRequest = firebaseCallable<
  GetUserHmacKeyRequest,
  GetUserHmacKeyResponse
>('getUserHmacKey');

export const getUserItinerariesRequest = firebaseCallable<
  GetUserOrdersRequest,
  GetUserItinerariesResponse
>('getUserItineraries');

export const getItineraryDrivingEstimateRequest = firebaseCallable<
GetItineraryDrivingEstimateRequest,
GetItineraryDrivingEstimateResponse
>('getItineraryDrivingEstimate');

export const getUserPasswordCreatedRequest = firebaseCallable<
  GetUserPasswordCreatedRequest,
  GetUserPasswordCreatedResponse
>('getUserPasswordCreated');

export const refreshTokenOnFailureRequest = firebaseCallable<
  RefreshTokenOnFailureRequest,
  void | RefreshTokenOnFailureResponse
>('refreshTokenOnFailure');

export const sendInvitationsToOfficeRequest = firebaseCallable<
  SendInvitationsToOfficeRequest,
  SendInvitationsToOfficeResponse
>('sendInvitationsToOffice');

export const setProcedureStatusesRequest = firebaseCallable<
  SetProcedureStatusesRequest,
  SetProcedureStatusesResponse
>('setProcedureStatuses');

export const setWaypointActionStatusRequest = firebaseCallable<
  SetWaypointActionStatusRequest,
  SetWaypointActionStatusResponse
>('setWaypointActionStatus');

export const withdrawUserAvailableBalanceRequest = firebaseCallable<
  WithdrawUserAvailableBalanceRequest,
  WithdrawUserAvailableBalanceResponse
>('withdrawUserAvailableBalance');

// TODO: Refactor below requests to be strongly typed.

export const changeUserEmailAddressRequest = firebaseCallable<
  unknown,
  unknown
>('changeUserEmailAddress');

export const resetPasswordRequest = firebaseCallable<
  ResetUserPasswordRequest,
  ResetUserPasswordResponse
>('resetUserPassword');

export const sendFinishSignUpEmailRequest = firebaseCallable<
  unknown,
  unknown
>('sendFinishSignUpEmail');

// Do not add any more cloud functions here, put them in alphabetical order
// in the top section.
