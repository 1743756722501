import { Role } from '@caresend/types';
import { HeadingValues } from '@caresend/ui-components';
import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesOffice: RouteConfig[] = [
  {
    path: '/:partnerName?',
    name: routeNames.OFFICE_HOME,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-home' */
      '@/views/office/OfficeHome.vue'
    ),
    meta: {
      authRequired: true,
      // Note: This route is matching all roles, though the component is only
      // rendered for office staff (assistants/prescribers) via a nested
      // router view.
      heading: (params) => computed<HeadingValues | undefined>(() => {
        if (params.store.state.auth.user?.role === Role.PACKER) {
          return {
            title: 'Packing & shipping',
          };
        }
      }),
      whiteBackground: ({ store }) => computed(() =>
        store.state.auth.user?.role === Role.PACKER,
      ),
    },
  },
  {
    path: '/:partnerName?/office/add-coworker/:newCoworkerRole?',
    name: routeNames.ADD_COWORKER,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-team' */
      '@/views/office/coworkers/AddCoworker/AddCoworker.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/:partnerName?/office/orders/',
    name: routeNames.OFFICE_ORDERS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-orders' */
      '@/views/office/orders/OrdersPage.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
  /** @deprecated */
  {
    path: '/:partnerName?/office/orders/:orderID',
    name: routeNames.OFFICE_ORDER_DETAILS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-orders' */
      '@/views/office/orders/subViews/OrderDetails/OrderDetails.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
  /** @deprecated */
  {
    path: '/:partnerName?/office/orders/:orderID/nurse-visits',
    name: routeNames.NURSE_VISITS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-orders' */
      '@/views/office/orders/subViews/OrderDetails/NurseVisits.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Nurse visits for this order',
        backLocation: {
          name: routeNames.OFFICE_ORDER_DETAILS,
        },
      })),
    },
  },
  /** @deprecated */
  {
    path: '/:partnerName?/office/orders/:orderID/request-filled',
    name: routeNames.REQUEST_FILLED,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-orders' */
      '@/views/office/orders/subViews/OrderDetails/subViews/RequestFilled.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: 'chunk-office-procedure' */
        '@caresend/ui-components/lib/src/components/specific/ProcedureDetails/ProcedureDetails/ProcedurePage.vue'
      ),
    children: [
      {
        path: '/:partnerName?/office/procedures/:procedureID/:view?',
        name: routeNames.OFFICE_PROCEDURE_DETAILS,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'chunk-office-procedure' */
            '@caresend/ui-components/lib/src/components/specific/ProcedureDetails/ProcedureDetails/ProcedureDetails.vue'
          ),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  /** @deprecated */
  {
    path: '/:partnerName?/office/bookings/:bookingID',
    name: routeNames.OFFICE_BOOKING_DETAILS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-bookings' */
      '@/views/office/orders/subViews/bookings/BookingDetails.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/:partnerName?/office/manage-team',
    name: routeNames.MANAGE_TEAM,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-team' */
      '@/views/office/coworkers/ManageTeam/ManageTeam.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/:partnerName?/office/reports',
    name: routeNames.REPORTS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-team' */
      '@/views/office/reports/Reports.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/:partnerName?/office/settings/:stepRouteSlug?/:childStepRouteSlug?',
    name: routeNames.OFFICE_SETTINGS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-office-settings' */
      '@/views/office/settings/OfficeSettings.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
];

export { routesOffice };
